
import {
  Component,
  Prop,
  Vue,
  Watch,
  Model,
  PropSync,
} from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

import {
  SimpleStaff,
  Dyestuff,
  UsedDyestuff1,
  UsedDyestuff2,
  Menu,
  OperationHistory,
  ScalpCare,
  TreatmentMenu,
} from '@/types'
import DyestuffFormList1 from './DyestuffFormList1.vue'
import DyestuffFormList2 from './DyestuffFormList2.vue'

// -export interface Form {
// -  readonly id: string | null
// -  treatmentStaff: string | null
// -  dyeingStaff1: string | null
// -  dyeingStaff2: string | null
// -  shampooStaff: string | null
// -  usedDyestuffRoots: UsedDyestuff1[]
// -  usedSolutionRoots: UsedDyestuff2[]
// -  usedDyestuffEnds: UsedDyestuff1[]
// -  usedSolutionEnds: UsedDyestuff2[]
// -  scalpProtectiveAgent: number | null
// -  note: string
// -  noteForNext: string
// -  dyestuffAmountRoot: null | number
// -  dyestuffAmountEnd: null | number
// -  [key: string]: null | string | number | UsedDyestuff1[] | UsedDyestuff2[]
// -}
export const initForm = () => ({
  treatmentStaff: null as null | string,
  dyeingStaff1: null as null | string,
  dyeingStaff2: null as null | string,
  shampooStaff: null as null | string,
  usedDyestuffRoots: [] as UsedDyestuff1[],
  usedSolutionRoots: [] as UsedDyestuff2[],
  usedDyestuffEnds: [] as UsedDyestuff1[],
  usedSolutionEnds: [] as UsedDyestuff2[],
  scalpProtectiveAgent: null as null | number,
  scalpProtectiveAgentType: null as null | number,
  note: '',
  noteForNext: '',
  dyestuffAmountRoot: null as null | number,
  dyestuffAmountEnd: null as null | number,
  treatmentName: '',
  trendColor: '0',
})

export type Form = ReturnType<typeof initForm>

@Component({
  components: {
    DyestuffFormList1,
    DyestuffFormList2,
  },
})
export default class ReservationOperationHistoryForm extends Vue {
  @Model('change', { type: Object, default: null }) readonly value!: Form | null
  @Prop({ type: Object, default: null })
  readonly operationHistory!: null | OperationHistory
  @Prop({ type: Object, default: () => ({}) }) readonly errors!: Dictionary<
    string[]
  >
  @Prop({ required: true }) readonly staffs!: SimpleStaff[]
  @Prop({ required: true }) readonly dyestuffs!: Dyestuff[]
  @PropSync('menu', { type: Number, default: null }) syncedMenu!: null | number
  @PropSync('treatment', { type: Number, default: null }) syncedTreatment!:
    | null
    | number
  @PropSync('scalpCare', { type: Number, default: null }) syncedScalpCare!:
    | null
    | number
  @Prop({ type: Array, required: true }) readonly menus!: Menu[]
  @Prop({ type: Array, required: true }) readonly treatments!: TreatmentMenu[]
  @Prop({ type: Array, required: true }) readonly scalpCares!: ScalpCare[]

  form = initForm()
  oldOH = null as null | OperationHistory

  color = null as null | number

  get colors() {
    const rval: any[] = []
    const ids: any = {}
    // @ts-ignore
    this.menus
      // @ts-ignore

      .map(m => m.color)
      .forEach(color => {
        if (color) {
          if (!(color.id in ids)) {
            ids[color.id] = true
            rval.push(color)
          }
        }
      })
    return rval
  }

  get filteredMenus() {
    const cid = this.color
    if (!cid) return this.menus
    return this.menus.filter(menu => menu.color?.id === cid)
  }

  @Watch('menu', { immediate: true })
  onMenuChanged(mid: number) {
    // @ts-ignore
    const m = this.menus.find(m => m.id === mid)
    if (m) {
      this.color = m?.color.id || null
    }
  }

  // get filteredMenuOptions() {
  //   return this.menuOptions.filter(x => x.name !== '薬剤追加')
  // }

  @Watch('operationHistory', { immediate: true })
  onOperationHistoryChanged(value: null | OperationHistory) {
    if (value) {
      if (
        this.oldOH?.id !== value.id &&
        this.oldOH?.updatedAt !== value.updatedAt
      ) {
        this.form = initForm()
        let form = this.form
        Object.keys(form).forEach(k => {
          // @ts-ignore
          form[k] = value[k]
        })
      }
    } else {
      this.form = initForm()
    }
    this.onChange()
    this.oldOH = value
  }

  @Watch('value', { deep: true })
  onValueChange(value: Form | null) {
    if (value && value !== this.form) Object.assign(this.form, value)
  }
  @Watch('treatment', { immediate: true })
  onTreatmentChanged(id: null | number) {
    let treatment = this.treatments.find(x => x.id === id)
    this.form.treatmentName = treatment?.shortName || ''
  }

  onChange() {
    const keys = [
      'treatmentStaff',
      'dyeingStaff1',
      'dyeingStaff2',
      'shampooStaff',
    ] as const
    let form = this.form
    keys.forEach(k => {
      if (form[k] === undefined) form[k] = null
    })
    this.$emit('change', form)
  }

  async copyPreviousDeystaffs() {
    let id = this.operationHistory?.id
    if (!id) return
    try {
      let {
        dyestuffAmountRoot,
        dyestuffAmountEnd,
        usedDyestuffRoots,
        usedSolutionRoots,
        usedDyestuffEnds,
        usedSolutionEnds,
      } = await this.$api.operationHistories(id).previous()
      let form = this.form
      if (dyestuffAmountRoot) {
        usedDyestuffRoots.forEach(x => (x.amount = Math.floor(x.amount * 0.9)))
        usedSolutionRoots.forEach(x => (x.amount = Math.floor(x.amount * 0.9)))
      }
      if (dyestuffAmountEnd) {
        usedDyestuffEnds.forEach(x => (x.amount = Math.floor(x.amount * 0.9)))
        usedSolutionEnds.forEach(x => (x.amount = Math.floor(x.amount * 0.9)))
      }
      form.usedDyestuffRoots = usedDyestuffRoots || []
      form.usedSolutionRoots = usedSolutionRoots || []
      form.usedDyestuffEnds = usedDyestuffEnds || []
      form.usedSolutionEnds = usedSolutionEnds || []
    } catch (e) {
      console.error(e)
      this.$toast.error('前回の染料の取得に失敗しました。')
    }
  }
}
