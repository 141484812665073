
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'

import FloatingBox from './FloatingBox.vue'
import {
  Reservation,
  ReservedBy,
  State,
  Menu,
  TreatmentMenu,
  Subscription,
} from '@/types'
// import { Dictionary } from 'vue-router/types/router'
import DyestuffText from './DyestuffText.vue'
const px = (x: number) => `${x}px`

@Component({
  components: {
    FloatingBox,
    DyestuffText,
  },
})
export default class TimelineReservationCard extends Vue {
  @Prop({ type: String, required: true }) readonly start!: string
  @Prop({ type: Object, required: true }) readonly reservation!: Reservation
  @Prop({ type: Array, required: true }) readonly menus!: Menu[]
  @Prop({ type: Array, required: true }) readonly treatments!: TreatmentMenu[]
  @Prop({ type: Array, required: true }) readonly segmentWidths!: number[]

  loading = false
  fetchAdditionalData = false
  isPrevLoading = false
  previous: null | Reservation = null
  subscription: null | Subscription = null // 最新のサブスクリプション
  // loadingPrev = false

  get royaltyRank() {
    const r = this.reservation
    if (!r) return null
    const rank = r.royaltyRank
    switch (rank) {
      case 5:
        return 'VIP'
      case 4:
        return 'ダイヤモンド'
      case 3:
        return 'プラチナ'
      case 2:
        return 'ゴールド'
      case 1:
        return 'シルバー'
      case null:
        return 'なし'
      default:
        return rank
    }
  }
  get planName() {
    return this.subscription?.planName
  }
  get subscExp() {
    const exp = this.subscription?.expiringAt
    if (exp)
      return moment(exp)
        .add(-1, 'days')
        .format('YYYY-MM-DD')
    return null
  }

  // icon用 getter
  get hasNote() {
    return this.reservation.note
  }
  get hasCustomerNote() {
    return this.reservation.customerNote
  }
  get hasCustomerUser() {
    return this.reservation.customerUserEmail
  }
  get hasCustomer() {
    return this.reservation.customer
  }
  get userIconColor() {
    const reserve = this.reservation
    const ecRegistered = Boolean(reserve.ecSiteUserCode)
    if (this.hasCustomerUser) {
      if (ecRegistered) return 'red'
      return 'orange'
    } else if (ecRegistered) {
      return 'blue'
    }
    return 'grey'
  }
  get isRT() {
    // 総来店数が4以上。かつ、今回の来店が前回から45日以内。
    let r = this.reservation
    if (!r.previousReservedDate) return false
    return (
      r.sumVisited > 3 &&
      moment(r.date).diff(r.previousReservedDate, 'days') <= 45
    )
  }
  get isHT() {
    // 総来店数が2or3。かつ、今回の来店が前回から45日以内。表示なしor 6 or 7 のいずれか
    let r = this.reservation
    if (!r.previousReservedDate) return false
    return (
      r.sumVisited > 1 &&
      moment(r.date).diff(r.previousReservedDate, 'days') <= 45
    )
  }
  get hasHotpepperPoint() {
    return this.reservation.hotpepperPoint !== null
  }
  get paid() {
    return this.reservation.paid
  }
  get reserveWayAbbrev() {
    switch (this.reservation.reservedBy) {
      case ReservedBy.Direct:
        return '直'
      case ReservedBy.Repeat:
        return '次'
      case ReservedBy.Phone:
        return '電'
      case ReservedBy.Hotpepper:
        return 'B'
      case ReservedBy.CallCenter:
        return 'C'
      case ReservedBy.ShopTablet:
        return '次タ'
      case ReservedBy.InShop2:
        return '店頭'
      case ReservedBy.App:
        return 'APP'
      case ReservedBy.Other:
        return '他'
      case ReservedBy.Line:
        return 'Li'
      case ReservedBy.PreOpen:
        return 'ハ'
      default:
        return '不明'
    }
  }

  get enableStarterPass() {
    const date = this.reservation.starterPassExpiry
    if (!date) return false
    return this.reservation.date <= date
  }

  get state() {
    let r = this.reservation
    if (r.isRemoved) return -1
    return r.state
  }
  get reserverFullNameKana() {
    return this.reservation.fullNameKana
  }
  get menuName() {
    return this.reservation.menuShortName
  }
  get treatmentName() {
    const id = this.reservation.treatment as null | number
    return this.treatments.find(x => x.id === id)?.shortName
  }
  // get menuOptionNames() {
  //   return this.reservation.menuOptionNames.map(s =>
  //     s.replace('トリートメント', '')
  //   )
  // }

  // get menu() {
  //   let id = this.reservation.menu
  //   return this.menus.find(x => x.id === id)
  // }
  // get menuColor() {
  //   let menu = this.menu
  //   if (!menu) return null
  //   const menuColors: Dictionary<string> = {
  //     部分染め: 'red',
  //     リタッチ: 'green',
  //     '全体染め(ショート)': 'blue',
  //     '全体染め(ミディアム)': 'cyan',
  //     '全体染め(ロング)': 'magenta',
  //     '全体染め(スーパーロング)': 'yellow',
  //     酸熱トリートメント: 'black',
  //   }
  //   return menuColors[menu.displayName] || 'grey'
  // }

  get barColor() {
    if ((this.reservation.scalpProtectiveAgentCount || 0) > 0) {
      return 'yellow'
    }
    return 'transparent'
  }

  get bgColor() {
    let state = this.reservation.state
    if (
      this.reservation.isRemoved ||
      state === State.Cancel ||
      state == State.NoShow
    ) {
      return 'rgb(190, 190, 190)'
    }
    if (state === State.Served) {
      return 'rgb(221, 221, 221)'
    }
    return null
  }
  get writtenOperationHistory() {
    // if (this.reservation.operationHistory === null)
    //   console.log('null OH', this.reservation.id)
    return Boolean(this.reservation.operationHistory?.treatmentStaff)
  }

  get prev() {
    return this.previous
  }

  get prevDeltaDays() {
    const date = this.prev?.date
    if (!date) return null
    return moment(this.reservation.date).diff(date, 'days')
  }

  // @Watch('reservation', { immediate: true })
  // onReserveChanged() {
  //   this.fetchPrev()
  // }

  // async fetchPrev() {
  //   let r = this.reservation
  //   if (!r.prevId || r.prev) return
  //   try {
  //     this.loadingPrev = true
  //     let prev = await this.$api.reservations(r.prevId).get()
  //     this.$set(r, 'prev', prev)
  //   } catch (err) {
  //     console.error(err)
  //   }
  //   this.loadingPrev = false
  // }

  @Watch('reservation.customer', { immediate: true })
  onCusotmerChanged(customer: null | string) {
    if (customer) this.fetchSubsc()
    else this.subscription = null
  }

  px = px

  onClick(ev: Event) {
    // console.log('reserve card onclick', ev)
    this.$emit('click', ev)
  }

  async changeState(state: State) {
    try {
      this.loading = true
      await this.$api.reservations(this.reservation.id).partialUpdate({ state })
    } catch (err) {
      console.error(err)
      this.$toast.error('状態の変更に失敗しました。')
    }
    this.loading = false
  }

  onMouseover() {
    if (this.fetchAdditionalData) return
    this.fetchAdditionalData = true

    this.fetchPrev()
    this.fetchSubsc()
  }
  async fetchPrev() {
    this.isPrevLoading = true
    try {
      this.previous = await this.$api
        .reservations(this.reservation.id)
        .previous()
    } catch (err) {
      console.error(err)
    }
    this.isPrevLoading = false
  }

  async fetchSubsc() {
    const customer = this.reservation.customer
    if (!this.reservation.subscription || !customer) return
    try {
      let { results } = await this.$api
        .subscriptions()
        .list({ customer: customer })

      results.sort((a, b) => b.expiringAt.localeCompare(a.expiringAt))
      this.subscription = results[0] || null
    } catch (err) {
      console.error(err)
    }
  }
}
